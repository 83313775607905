import { saveLocation } from "../app";
import { hasClass, removeClass, addClass } from "./helperFunctions";
import { showNextPrevButtons } from "./showNextPrevButtons";
import { getVotD } from "./getVotD";
import { getCommentary } from "./getCommentary";
import { ChatonBoardingCheck } from "./onboarding";
import { resetAudioButton, pauseAudio, pauseAudioButton } from "./audio";

//on Page load check if there is a chapter in the URL
export function checkURL() {
  // console.log("checking URL");
  //only if the pathname contains NMV (it is a book of the bible) or the root page. This keeps this function from being fired on the download and FAQ pages.
  if (
    window.location.pathname.includes("NMV") ||
    window.location.pathname === "/"
  ) {
    getCommentary();
    getVotD();
    if (window.location.hash !== "") {
      var addressHash = window.location.hash;
      var urlChapter = addressHash.replace('#', "");

      chapterSelect(urlChapter);
      showNextPrevButtons(0);
      let audioPlayer = document.querySelector("#AudioBiblePlayer");
      if (audioPlayer) {
        pauseAudio();
        pauseAudioButton();
        resetAudioButton();
      }
      //save pageview count and showing the chat onboarding modal if there has been more than 2 pageviews and it hasn't been shown before
      ChatonBoardingCheck();
    } else {
      //it is loading the first chapter of the book without a hash to ch1. It needs to hide the prev button.
      NextPrevChanger(1);
      showNextPrevButtons(0);
      //save Location for later
      if (window.location.pathname !== "/") {
        saveLocation(window.location.pathname + "#ch1");
      }
      //save pageview count and showing the chat onboarding modal if there has been more than 2 pageviews and it hasn't been shown before
      ChatonBoardingCheck();
    }
  }
}
//gets the href from the clicked link and passes it on to the chapterSelect. This was done to make the chapterSelect function more usuable in other areas.
export function getChapterLink(event) {
  var link = this.pathname;
  var hash = this.hash;
  var fullLink = link + hash;
  //ga("send", "event", "BookSelector", "Chapter Number Clicked");
  if (hasClass(this, "newBook") | (window.location.pathname !== link)) {
    saveLocation(fullLink);
    // console.log("changing books");
  } else {
    event.preventDefault();
    saveLocation(fullLink);
    chapterSelect(hash);
    removeParams();
    window.scroll(0, 0);
  }
}
//show the selected chapter and hide any other chapters as well as the Book/Chapter Selectors
function chapterSelect(link) {
  var chapter = link.replace("#", "");
  var verse;
  if (chapter.includes(".")) {
      verse = chapter.split('.')[1];
      chapter = chapter.split('.')[0];
  }
   
  var chapterNumber = chapter.replace("ch", "");
  //find and hide currently showing chapter.
  var currentlyShowing = document.querySelector(".chapter.show");
  if (currentlyShowing) {
    removeClass(currentlyShowing, "show");
  }
  //show the selected chapter, change the chapterListToggle number, hide the chapterList
  addClass(document.getElementsByClassName("chapter " + chapter)[0], "show");
  //if the chapter is 0 put Intro in the header instead of 0
  if (chapterNumber == 0) {
    document.getElementById("chapterListToggle").innerHTML = "مقدمه";
  } else {
    document.getElementById("chapterListToggle").innerHTML = chapterNumber;
  }
  NextPrevChanger(chapterNumber);
  changeURL(link);
  removeClass(document.querySelector("#Books_list"), "show");

  if (verse) {
    scrollToVerse(chapter, verse);
  }
}

function scrollToVerse( chapter, verse) {
  var verseElements = document.querySelectorAll(`.${chapter} .verse.v${verse}`)
  verseElements[0].scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
  
  verseElements.forEach(function(verseElement) {
    addClass(verseElement, "searchHighlight");
  })
  

}

function removeParams() {
  if (window.location.search) {
    var newurl = window.location.href.replace(window.location.search, "");
    history.replaceState({}, "", newurl);
  }
}
//add the chapter number to the url hash. This is used instead of standard GET params because those don't cache in AppCache very well.
function changeURL(anchorHash) {
  window.location.hash = anchorHash;
  //This is for Google Analytics Tracking
  var realPath = window.location.pathname;
  var chapter = anchorHash.replace("#", "");

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
  'event': 'Pageview',
  'pagePath': window.location.href,
  'pageTitle': `${document.title} - ${chapter}`
  });

  // console.log("sending new page url" + fakePath);
  //ga("set", "page", fakePath);
  //ga("send", "pageview");
}
function NextPrevChanger(currentChapter) {
  var currentBook = window.location.pathname;
  //Don't change the links on the index.html page, these are hardcoded to try and increase initial page load.
  if (currentBook !== "/") {
    currentChapter = Number(currentChapter);
    var prev = currentChapter - 1;
    var next = currentChapter + 1;
    var bookArray = [
      "/NMV/NMV-01-GEN.html",
      "/NMV/NMV-02-EXO.html",
      "/NMV/NMV-03-LEV.html",
      "/NMV/NMV-04-NUM.html",
      "/NMV/NMV-05-DEU.html",
      "/NMV/NMV-06-JOS.html",
      "/NMV/NMV-07-JDG.html",
      "/NMV/NMV-08-RUT.html",
      "/NMV/NMV-09-1SA.html",
      "/NMV/NMV-10-2SA.html",
      "/NMV/NMV-11-1KI.html",
      "/NMV/NMV-12-2KI.html",
      "/NMV/NMV-13-1CH.html",
      "/NMV/NMV-14-2CH.html",
      "/NMV/NMV-15-EZR.html",
      "/NMV/NMV-16-NEH.html",
      "/NMV/NMV-17-EST.html",
      "/NMV/NMV-18-JOB.html",
      "/NMV/NMV-19-PSA.html",
      "/NMV/NMV-20-PRO.html",
      "/NMV/NMV-21-ECC.html",
      "/NMV/NMV-22-SNG.html",
      "/NMV/NMV-23-ISA.html",
      "/NMV/NMV-24-JER.html",
      "/NMV/NMV-25-LAM.html",
      "/NMV/NMV-26-EZK.html",
      "/NMV/NMV-27-DAN.html",
      "/NMV/NMV-28-HOS.html",
      "/NMV/NMV-29-JOL.html",
      "/NMV/NMV-30-AMO.html",
      "/NMV/NMV-31-OBA.html",
      "/NMV/NMV-32-JON.html",
      "/NMV/NMV-33-MIC.html",
      "/NMV/NMV-34-NAM.html",
      "/NMV/NMV-35-HAB.html",
      "/NMV/NMV-36-ZEP.html",
      "/NMV/NMV-37-HAG.html",
      "/NMV/NMV-38-ZEC.html",
      "/NMV/NMV-39-MAL.html",
      "/NMV/NMV-41-MAT.html",
      "/NMV/NMV-42-MRK.html",
      "/NMV/NMV-43-LUK.html",
      "/NMV/NMV-44-JHN.html",
      "/NMV/NMV-45-ACT.html",
      "/NMV/NMV-46-ROM.html",
      "/NMV/NMV-47-1CO.html",
      "/NMV/NMV-48-2CO.html",
      "/NMV/NMV-49-GAL.html",
      "/NMV/NMV-50-EPH.html",
      "/NMV/NMV-51-PHP.html",
      "/NMV/NMV-52-COL.html",
      "/NMV/NMV-53-1TH.html",
      "/NMV/NMV-54-2TH.html",
      "/NMV/NMV-55-1TI.html",
      "/NMV/NMV-56-2TI.html",
      "/NMV/NMV-57-TIT.html",
      "/NMV/NMV-58-PHM.html",
      "/NMV/NMV-59-HEB.html",
      "/NMV/NMV-60-JAS.html",
      "/NMV/NMV-61-1PE.html",
      "/NMV/NMV-62-2PE.html",
      "/NMV/NMV-63-1JN.html",
      "/NMV/NMV-64-2JN.html",
      "/NMV/NMV-65-3JN.html",
      "/NMV/NMV-66-JUD.html",
      "/NMV/NMV-67-REV.html"
    ];
    var chapterTotalsArray = [
      "#ch50",
      "#ch40",
      "#ch27",
      "#ch36",
      "#ch34",
      "#ch24",
      "#ch21",
      "#ch4",
      "#ch31",
      "#ch24",
      "#ch22",
      "#ch25",
      "#ch29",
      "#ch36",
      "#ch10",
      "#ch13",
      "#ch10",
      "#ch42",
      "#ch150",
      "#ch31",
      "#ch12",
      "#ch8",
      "#ch66",
      "#ch52",
      "#ch5",
      "#ch48",
      "#ch12",
      "#ch14",
      "#ch3",
      "#ch9",
      "#ch1",
      "#ch4",
      "#ch7",
      "#ch3",
      "#ch3",
      "#ch3",
      "#ch2",
      "#ch14",
      "#ch4",
      "#ch28",
      "#ch16",
      "#ch24",
      "#ch21",
      "#ch28",
      "#ch16",
      "#ch16",
      "#ch13",
      "#ch6",
      "#ch6",
      "#ch4",
      "#ch4",
      "#ch5",
      "#ch3",
      "#ch6",
      "#ch4",
      "#ch3",
      "#ch1",
      "#ch13",
      "#ch5",
      "#ch5",
      "#ch3",
      "#ch5",
      "#ch1",
      "#ch1",
      "#ch1",
      "#ch22"
    ];
    var arrayPosition = bookArray.indexOf(currentBook);
    var prevLink;
    var nextLink;
    var nextButton = document.querySelector(".next");
    var nextButton_Commentary = document.querySelector(
      ".commentaryChapterButton"
    );
    var prevButton = document.querySelector(".prev");
    var lastChapterHash = document
      .querySelector("#Books_list .current .chapterList")
      .lastElementChild.children[0].getAttribute("href");
    if (currentChapter == lastChapterHash.split("#ch")[1]) {
      // console.log("this is the last chapter");
      //this checks to see if this is the last chapter of Revelation. If so it hides the next button. If not it removes the hide class and carries on.
      if (bookArray[arrayPosition + 1]) {
        nextLink =
          window.location.origin + bookArray[arrayPosition + 1] + "#ch1";
        addClass(nextButton, "newBook");
        removeClass(nextButton, "hide");
      } else {
        addClass(nextButton, "hide");
      }
    } else {
      // console.log("this is not the last chapter");
      nextLink = "#ch" + next;
      removeClass(nextButton, "newBook");
      removeClass(nextButton, "hide");
    }
    if (currentChapter == 1) {
      // console.log("this is the first chapter");
      //this checks to see if this is the first chapter of Genesis. If so it hides the prev button. If not it removes the hide class and carries on.
      if (bookArray[arrayPosition - 1]) {
        prevLink =
          window.location.origin +
          bookArray[arrayPosition - 1] +
          chapterTotalsArray[arrayPosition - 1];
        addClass(prevButton, "newBook");
        removeClass(prevButton, "hide");
      } else {
        addClass(prevButton, "hide");
      }
    } else {
      // console.log("this is not the first chapter");
      prevLink = "#ch" + prev;
      removeClass(prevButton, "newBook");
      removeClass(prevButton, "hide");
    }
    // console.log(prevLink);
    // console.log(nextLink);
    if (prevButton) {
      prevButton.setAttribute("href", prevLink);
    }
    if (nextButton) {
      nextButton.setAttribute("href", nextLink);
      nextButton_Commentary.setAttribute("href", nextLink);
    }
  }
}
