import { addClass, removeClass, hasClass, toggleClass } from "./helperFunctions";

let audioButtonContainer = document.querySelector("body > div.AudioButton.mainAudioButton");
let audioButtons = document.querySelectorAll("svg.play-button");
let play = document.querySelector("#play");
let pause = document.querySelector("#pause");
let circle = document.querySelector(".playButton_Circle")

audioButtons.forEach(audioButton => {
    audioButton.addEventListener("click", function() {
        if ( hasClass(this, "playing") ) {
            pauseAudioButton(this);
            pauseAudio();
        } else {
            playAudioButton(this);
            initAudioPlayer(this);
        }
    })
}
);

export const pauseAudioButton = () => {
    let audioButtons = document.querySelectorAll("svg.play-button");
    audioButtons.forEach(audioButton => {
        removeClass(audioButton, "playing");
    })
}

export const playAudioButton = (audioButton) => {
    addClass(audioButton, "playing");
}

export const resetAudioButton = () => {
        let audioPlayer = document.querySelector("#AudioBiblePlayer");
        let audioButtons = document.querySelectorAll("svg.play-button");
        audioButtons.forEach(audioButton => {
            removeClass(audioButton, "playing");
        })
        audioPlayer.removeEventListener("timeupdate", audioProgress)

        document.querySelector('.playButton_Circle').style.strokeDashoffset = 0;
        document.querySelector('.playButton_ProgIndicator_Circle').style.strokeDashoffset = 1928;
}

export const initAudioPlayer = () => {
    if (window.location.pathname === '/') {
        let AudioPlayer = document.querySelector("#AudioBiblePlayer");
        if (!hasClass(audioButtonContainer, "loaded")) {
            addClass(audioButtonContainer, "loading");
            AudioPlayer.load();
            AudioPlayer.addEventListener("canplay", event => {
                /* the audio is now playable; play it if permissions allow */
                addClass(audioButtonContainer, "loaded");
                removeClass(audioButtonContainer, "loading");
                playAudio();
            });
        } else {
            playAudio();
        }
        return;
    }
    var bookPath = window.location.pathname;
    var addressHash = window.location.hash;
    var urlChapter = addressHash.replace('#ch', "");
    urlChapter = ("0" + urlChapter).slice(-2);
    var bookPathParts = bookPath.match('\/(.*)\/(.*).html');
    var audioPath = `/${bookPathParts[1]}/Audio/${bookPathParts[2]}/${bookPathParts[2]}-${urlChapter}.mp3`;

    var audioURL = window.location.origin + audioPath;
 
    let AudioPlayer = document.querySelector("#AudioBiblePlayer");

    if (AudioPlayer) {
        if (AudioPlayer.src == audioURL) {
            playAudio();
        } else {
            document.querySelector("#AudioBiblePlayer").remove();
            loadAudio(audioPath);
        }
    } else {
        loadAudio(audioPath);
    }
}

export const loadAudio = (audioPath) => {
    var audio = new Audio(audioPath);
    audio.id = "AudioBiblePlayer";
    audioButtonContainer.appendChild(audio).id;
    addClass(audioButtonContainer, "loading");

    audio.addEventListener("canplaythrough", event => {
        /* the audio is now playable; play it if permissions allow */
        removeClass(audioButtonContainer, "loading");
    });
    playAudio();
}
export const playAudio = () => {
    let audioPlayer = document.querySelector("#AudioBiblePlayer");
    audioPlayer.play();
    audioPlayer.addEventListener("timeupdate", audioProgress );
    audioPlayer.addEventListener("ended", function() {
        resetAudioButton();
    })
}

export const pauseAudio = () => {
    let audioPlayer = document.querySelector("#AudioBiblePlayer");
    audioPlayer.pause();
}

export const audioProgress = () => {
    var audio = document.querySelector("#AudioBiblePlayer");
    // var circle = document.querySelector('.playButton_Circle');
    var progIndicator_Circle = document.querySelector('.playButton_ProgIndicator_Circle');
    addClass(progIndicator_Circle, "playing");
    var perctComplete = audio.currentTime / audio.duration;
    // let dashOffset = -perctComplete * 1928;
    let progOffset = 1928 - (perctComplete * 1928);
    // circle.style.strokeDashoffset = dashOffset;
    progIndicator_Circle.style.strokeDashoffset = progOffset;
}

var last_known_scroll_position = 0;

export const minimizeAudioButton = (scroll_pos) => {
    let classMinimizer = "audio_minimized";
    if (scroll_pos !== 0 ) {
        addClass(audioButtonContainer, classMinimizer);
      } else {
        removeClass(audioButtonContainer, classMinimizer);
      }
      last_known_scroll_position = scroll_pos;
}